<template>
  <Countdown
    v-slot="{ days, hours, minutes, seconds, complete }"
    tag="span"
    :to="to"
    :format="format"
    class="CountdownText"
    @complete="onComplete"
  >
    <template v-if="!complete">
      <span v-if="text" class="CountdownText__text">{{ text }}</span>
      <span class="CountdownText__time">
        <span v-if="!(trim && days === 0)">
          {{ $tc('date.day--n_plural', days) }}
        </span>
        <span v-if="!(trim && days === 0 && hours === 0)">
          {{ $tc('time.hour--n_plural', hours) }}
        </span>
        <span v-if="!(trim && days === 0 && hours === 0 && minutes === 0)">
          {{ $tc('time.minute--n_plural', minutes) }}
        </span>
        <span>
          {{ $tc('time.second--n_plural', seconds) }}
        </span>
      </span>
    </template>
  </Countdown>
</template>

<script>
import { Countdown } from '@components/Countdown';

export default {
  name: 'CountdownText',

  components: {
    Countdown,
  },

  props: {
    to: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      default: undefined,
    },
    trim: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
  },
  methods: {
    onComplete(initial) {
      if (!initial) location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.CountdownText {
  .CountdownText__text {
    display: block;
  }
  .CountdownText__time > span {
    display: inline-block;
    &:not(:last-child) {
      margin-right: 0.6em;
    }
  }
}
</style>
