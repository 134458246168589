<template>
  <component :is="tag" class="Countdown">
    <slot
      name="default"
      :days="days"
      :hours="hours"
      :minutes="minutes"
      :seconds="seconds"
      :complete="complete"
    />
  </component>
</template>

<script>
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'; // Load on demand
dayjs.extend(customParseFormat);

export default {
  name: 'Countdown',

  props: {
    tag: {
      type: String,
      default: 'span',
    },
    to: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      default: undefined,
    },
    refreshInterval: {
      type: Number,
      default: 1000,
    },
  },

  data() {
    return {
      dateNow: null,
      complete: false,
    };
  },

  computed: {
    toWithFormat() {
      if (this.to && this.format) {
        return dayjs(this.to, this.format).toISOString();
      }
      return this.to;
    },
    timeLeft() {
      const timeLeft = new Date(this.toWithFormat) - (this.dateNow || Date.now());
      return timeLeft > 0 ? timeLeft : 0;
    },
    totalSeconds() {
      return Math.floor(this.timeLeft / 1000);
    },
    totalMinutes() {
      return Math.floor(this.totalSeconds / 60);
    },
    totalHours() {
      return Math.floor(this.totalMinutes / 60);
    },
    totalDays() {
      return Math.floor(this.totalHours / 24);
    },
    seconds() {
      return this.totalSeconds % 60;
    },
    minutes() {
      return this.totalMinutes % 60;
    },
    hours() {
      return this.totalHours % 24;
    },
    days() {
      return this.totalDays;
    },
  },

  watch: {
    timeLeft: {
      immediate: true,
      handler(to, from) {
        if (to <= 0) {
          this.complete = true;

          if (from !== undefined) {
            this.$emit('complete', false);
          } else {
            this.$emit('complete', true);
          }
        }
      },
    },
  },

  created() {
    this.update();
  },

  mounted() {
    this.interval = setInterval(this.update, this.refreshInterval);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    update() {
      this.dateNow = Date.now();
    },
  },
};
</script>
