<template>
  <Countdown
    :to="to"
    :format="format"
    v-slot="{ days, hours, minutes, seconds }"
    class="CountdownBanner"
  >
    <h2 v-if="title" class="ft-h3">{{ title }}</h2>
    <div class="CountdownBanner__content">
      <div class="CountdownBanner__item">
        <span class="CountdownBanner__time">{{ days }}</span>
        <span class="CountdownBanner__label">{{ $tc('date.day--plural', days) }}</span>
      </div>
      <div class="CountdownBanner__item">
        <span class="CountdownBanner__time">{{ hours }}</span>
        <span class="CountdownBanner__label">{{ $tc('time.hour--plural', hours) }}</span>
      </div>
      <div class="CountdownBanner__item">
        <span class="CountdownBanner__time">{{ minutes }}</span>
        <span class="CountdownBanner__label"> {{ $tc('time.minute--plural', minutes) }}</span>
      </div>
      <div class="CountdownBanner__item">
        <span class="CountdownBanner__time">{{ seconds }}</span>
        <span class="CountdownBanner__label">{{ $tc('time.second--plural', seconds) }}</span>
      </div>
    </div>
  </Countdown>
</template>

<script>
import { Countdown } from '@components/Countdown';

export default {
  name: 'CountdownBanner',

  components: {
    Countdown,
  },

  props: {
    to: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.CountdownBanner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .CountdownBanner__content {
    display: flex;
    justify-content: center;
    gap: 40px;
  }

  .CountdownBanner__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    border-radius: $border-radius;
    padding: 10px;
    width: 110px;
    height: 110px;
  }

  .CountdownBanner__time {
    font-size: 50px;
    line-height: 100%;
    color: #ffffff;
  }

  .CountdownBanner__label {
    font-size: 14px;
    line-height: 100%;
    color: #ffffff;
  }

  @include maxBreakpoint($laptopS-numeric) {
    .CountdownBanner__content {
      gap: 20px;
    }

    .CountdownBanner__time {
      font-size: 30px;
    }

    .CountdownBanner__item {
      width: 80px;
      height: 80px;
    }
  }

  @include maxBreakpoint($mobileL-numeric) {
    .CountdownBanner__time {
      font-size: 25px;
    }

    .CountdownBanner__label {
      font-size: 12px;
    }

    .CountdownBanner__item {
      width: 70px;
      height: 70px;
    }
  }

  @include maxBreakpoint($mobileM-numeric) {
    .CountdownBanner__content {
      gap: 10px;
    }

    .CountdownBanner__item {
      width: 60px;
      height: 60px;
    }

    .CountdownBanner__time {
      font-size: 18px;
    }
  }
}
</style>
