<template>
  <div class="CountdownBannerWidget">
    <CountDownBanner :title="title" :to="end" :format="format" />
  </div>
</template>

<script>
import CountDownBanner from '../CountdownBanner';
export default {
  name: 'CountdownBannerWidget',
  components: {
    CountDownBanner,
  },

  props: {
    start: {
      type: String,
      default: '',
    },
    end: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.CountdownBannerWidget {
  margin: 20px 0;
}
</style>
